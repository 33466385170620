import axios from "axios";

const MAIN_API = process.env.REACT_APP_MAIN;
const HELPDESK_API = process.env.REACT_APP_HELPDESK;
const ES_API = process.env.REACT_APP_ES;

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const HTTP_Method = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export function Client(query, params, header, controller) {
  return {
    // general
    API_Company_Profile_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/company/profile`,
        data: params,
        headers,
      }),
    API_Check_Token: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/check-token`,
        data: params,
        headers,
      }),
    API_Update_Next_Link: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/update/next-link`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Menus_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/menus`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Referral_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/referral`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Client_Preference_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/preference`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Notification_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/notification`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Notification_Visit: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/notification/${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Verify_OTP: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/verify-code`,
        data: params,
        headers,
      }),
    API_Resend_OTP: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/resend-otp`,
        data: params,
        headers,
      }),

    // terms
    API_Terms_And_Conditions: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/term-conditions`,
        params,
        headers: {
          ...headers,
          // Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Terms_And_Conditions_Agreed: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/agreed/terms`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Terms_Docu_Sign: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/docusign`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // login
    API_Login: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/login`,
        data: params,
        headers,
      }),

    // logout
    API_Logout: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/logout`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // register
    API_Register: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/register`,
        data: params,
        headers,
      }),

    // fogot-password
    API_Forgot_Password: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/forgot-password`,
        data: params,
        headers,
      }),

    // reset-password
    API_Reset_Password: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/reset-password`,
        data: params,
        headers,
      }),

    // payment setup
    API_Configuration_Payment: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/payment`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Save_Payment: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/payment`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    // job
    API_Job_Positions: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/job-position`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Timezones: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/timezone-utc`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Job_Experiences: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/job-experience`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Job_Timezones: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/timezone`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Job_Shift: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/job-shift`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Job_Budgets: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/job-budget`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Job_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/preference`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // new applicants management
    API_Applicant_Filters: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/search/filters`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_ES_Applicant_Filters: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${ES_API}/v1/badges/get`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Applicant_Search: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/applicant/search${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_ES_Applicant_Search: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${ES_API}/v1/resumes/search${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Applicant_SearchInput: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/applicant/search${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_ES_Applicant_SearchInput: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${ES_API}/v1/resumes/search-query${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Applicant_Resume: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/applicant/resume/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Applicant_Interested: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/resume`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Candidate_List_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/candidate/${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    API_Applicant_Resume_Public: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/resume/view/${query}`,
        data: params,
        headers: { ...headers },
      }),

    // hired applicants management
    API_Hired_Applicants_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/applicant/hired/list${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Hired_Applicant_Information_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/applicant/hired/${query}/information`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Hired_Applicant_Documents_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/employee/folder/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    // profile
    API_Profile_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/profile/company-information`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Profile_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/profile`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Industry_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/industry`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Country_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/country`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Region_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/region/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_State_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/state/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_City_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/city/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_City_By_Postal_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/city-by-postal/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Office_Detail_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/profile/office-detail`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    API_Contact_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/profile/contact`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    API_Contact_Delete: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/profile/contact`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Documents_And_Photos_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/document/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Documents_And_Photos_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/document`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Documents_And_Photos_Delete: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/document/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Sow_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/applicant/hired/${query}/sow`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Sow_Sign: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/sow/signed/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    API_Appraisal_Questionnaire_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/appraisal/fields`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    // roles
    API_Roles_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/roles${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Roles_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/roles`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Roles_View: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/roles/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Roles_Update: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/roles`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Roles_Delete: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/roles/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Roles_Archives_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/archive-roles${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Roles_Archives_Restore: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/archive-roles/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Roles_Archives_Delete: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/archive-roles/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // user
    API_User_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/user${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_User_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/user`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_User_View: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/user/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_User_Update: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/user`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_User_Delete: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/user/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_User_Change_Password: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/change-password`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_User_Archives_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/archive-user${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_User_Archives_Restore: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/archive-user/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_User_Archives_Delete: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/archive-user/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // asset
    API_Asset_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/request/asset${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Asset_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/request/asset`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Asset_Cancel: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/request/asset/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Asset_Employee_Search: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/search/users${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    // billing
    API_Billing_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/billing/list${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Billing_Dispute_Update: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/billing/update/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    // invoice
    API_Invoice_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/invoice/get`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Invoice_View: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/invoice/get/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Invoice_Summary_View: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/invoice/get/${query}/summary`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    // invoice list
    API_Invoice_List_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/invoice/list${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    API_Download_Invoice_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/invoice/download/${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        responseType: "blob",
      }),

    // referrals
    API_Referrals_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/referral/list${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),

    API_Download_Resume_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/download/resume/${query}`,
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        responseType: "blob",
      }),

    // get actual skills from role id
    API_Actual_Skills_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/job/role/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // scheduler
    API_Scheduler_Get: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/meeting${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Scheduler_Get_Without_Query: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/meeting`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
        signal: controller?.signal,
      }),
    API_Scheduler_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/meeting`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Scheduler_View: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/meeting/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Scheduler_Update: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/meeting`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Scheduler_Delete: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/meeting/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Scheduler_Participants: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/user`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Scheduler_Google_Calendar_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/google-calendar`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    API_Scheduler_Outlook_Calendar_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/outlook-calendar`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // zoom meeting
    API_Interview_Save: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/interview`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // job requirements
    API_Job_Requirements_config: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/job-requirement/fields`,
        data: null,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Save_Job_Requirements: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/job`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Job_Requirement: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/job`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Get_Job_Requirement: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/job/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Get_Job_Requirements_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/job${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Delete_Job_Requirement: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/job/${query}/1`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Restore_Job_Requirement: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/job/${query}/0`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Delete_Permanent_Job_Requirement: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/job/${query}/2`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // filling
    API_Filling_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/form/approval/list/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Filling_Set_Status: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/form/approval/update/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // time record
    API_Retrieve_Schedule: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/work-schedule${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Retrieve_Time_Logs: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/time/logs${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Save_Work_Schedule: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/work-schedule`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // tasks
    API_Retrieve_Tasks: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/task/list${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Save_Task: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/task/save`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Retrieve_Task: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/task/get${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Task: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/task/update${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // appraisal
    API_Save_Appraisal: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/appraisal/save`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_List_Appraisal: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/appraisal/list${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Cancel_Appraisal: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/appraisal/cancel/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // request
    API_Request_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/request/salary${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Reimbursement_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/reimbursement/list${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Reimbursement_Config: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/reimbursement/get${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Request_Increase: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/request/salary`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Request_Reimbursement: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/reimbursement/save`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Request_Cancel: () =>
      axios({
        method: HTTP_Method.PUT,
        url: query ? `${MAIN_API}/client/request/salary/${query}` : `${MAIN_API}/client/request/salary`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    // set meeting by hr
    API_Set_Meeting_HR: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/manual/meeting`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    // get dashboard counters
    API_Get_Dashboard_Counters: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/dashboard`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    // get available users
    API_Get_Available_Users: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/user/availability`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    // get sow list
    API_Get_Sow_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/sow/list/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    // get sow file
    API_Get_Sow_File: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/sow/get/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    // sign sow
    API_Sign_Sow_File: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/sow/signed/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Get_Departments: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/configuration/department`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Employee_Profile: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/employee/profile`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Employee_Credentials: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/employee/user`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Employee_Department: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/applicant/hired/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // departments
    API_Get_Departments_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/client/department/list/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Save_Department: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${MAIN_API}/client/department/save`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Department: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/department/update/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Status_Department: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/department/delete/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // sow
    API_Sow_Question: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/sow/concern/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // update candidate status
    API_Candidate_Update_Status: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/candidate/update/status`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // cancel jo
    API_Cancel_Job_Offer: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/job-offer/update/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // favorite list
    API_Get_Favorite_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/resume/interest/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // approve asset
    API_Approve_Asset_Request: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/request/asset/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // check prorated
    API_Check_Prorated_Adjustment: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${MAIN_API}/pro-rated/salary/adjustment${query}`,
        data: null,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),

    // update clearance status
    API_Update_Clearance_Status: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${MAIN_API}/client/clearance/${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    // delete accountability
    API_Delete_Accountability: () =>
      axios({
        method: HTTP_Method.DELETE,
        url: `${MAIN_API}/client/clearance/accountability/${query}`,
        data: null,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Get_Ticket_List: () =>
      axios({
        method: HTTP_Method.GET,
        url: `${HELPDESK_API}/faq${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Request_Ticket: () =>
      axios({
        method: HTTP_Method.POST,
        url: `${HELPDESK_API}/faq`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
    API_Update_Ticket: () =>
      axios({
        method: HTTP_Method.PUT,
        url: `${HELPDESK_API}/faq${query}`,
        data: params,
        headers: {
          ...headers,
          Authorization: `Bearer ${header["x-access-token"]}`,
        },
      }),
  };
}
